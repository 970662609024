<template>
<el-container class="iot-system-container" style="height:100%;">
    
  <el-card class="iot-person public-page-style iot-search-header" style="height:100%;">
  <div >
    <div class="weeklyTitle">
      <el-button
              type="primary"
              size="mini"
               v-if="$store.state.permissionArr.indexOf('templateWeekly:add') != -1"
              @click="addWeekly()"
              plain
              >新增模板</el-button
            >
    </div>
    <div style="height:678px;overflow-y: auto;">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="templateWeeklyName" label="模板名字" width="180">
        </el-table-column>

        <el-table-column label="关联(二级账户)">
          <template slot-scope="scope">
            <span v-for="(item,index) in scope.row.userList"  :key="index">
              <span v-if="item">
                {{ item.fullName }}、
              </span>
              
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注"> </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
               v-if="$store.state.permissionArr.indexOf('templateWeekly:update') != -1"
              @click="editWeekly(scope.row.templateWeeklyId)"
              plain
              >修改</el-button
            >
            <el-button
              type="danger"
              size="mini"
              v-if="$store.state.permissionArr.indexOf('templateWeekly:delete') != -1"
              @click="delTemplateWeekly(scope.row.templateWeeklyId)"
              plain
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
       </div>
        <div style="display:flex;justify-content: flex-end;">
      <el-pagination
        background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageForm.current"
      :page-sizes="[10, 20, 30, 40]"
        :total="pageForm.total"
        :page-size="pageForm.size"
        style=""
        layout="total, sizes, prev, pager, next, jumper"
        @prev-click="
          () => {
            $set(pageForm, 'current', pageForm.current - 1);
            getTemplateWeeklyPage();
          }
        "
        @next-click="
          () => {
            $set(pageForm, 'current', pageForm.current + 1);
            getTemplateWeeklyPage();
          }
        "
      >
      </el-pagination>
    </div>
   
    <el-dialog top="3%" :title="diaTitle" :visible.sync="weeklyVisible">
      <div style="width:100%;position: relative;" class="form">
        <div style="position: absolute;
 top: 0;
right: 0;">
             <el-button type="primary" @click="examplesVisible=true"
          >示 例</el-button
        >
        </div>
        <el-form ref="form" :model="weeklyForm" label-width="80px" >
          <el-form-item label="模板名称">
            <el-input v-model="weeklyForm.templateWeeklyName"></el-input>
          </el-form-item>
          <el-form-item label="关联账号">
            <el-select collapse-tags filterable  v-model="checkUsers" multiple placeholder="请选择">
              <el-option
                v-for="item in userList"
                :key="item.userId"
                :label="item.fullName"
                :value="item.userId"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" style="position: relative;">
            <el-input v-model="weeklyForm.remarks"></el-input> 
     
          </el-form-item>
        
        <el-form-item label="关键数据"> 
        <el-form
          ref="form"
          :inline="true"
          v-for="(items,index) in weeklyForm.applList"
          :key="index"
          label-width="80px"
        >
          <el-form-item label="选择方案">
            <el-select
             filterable 
              v-model="items.planId"
              @change="getplanIdProtocolList(items)"
              placeholder="请选择"
            >
              <el-option
                v-for="item in planList"
                :key="item.planId"
                :label="item.planName"
                :value="item.planId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="数据">
            <el-select v-model="items.dataApplicationId" filterable  placeholder="请选择">
              <el-option
                v-for="item in applicationList[items.planId]"
                :key="item.dataApplicationId"
                :label="item.dataApplicationName"
                :value="item.dataApplicationId"
              >
              </el-option>
            </el-select>
            <el-button style="margin-left:10px;" @click="delApplList(index)" size="mini" type="danger" icon="el-icon-minus" circle></el-button>
          </el-form-item>
        </el-form>
         </el-form-item>
        </el-form>
     
        
      </div>

      <div slot="footer" class="dialog-footer">
      
         <el-button type="primary" @click="addApplList(weeklyForm.applList)"
          >添加参数</el-button
        >
        
        <el-button type="primary" @click="addTemplateWeekly"
          >确 定</el-button
        >
        <el-button @click="weeklyVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
     
  :visible.sync="examplesVisible"
  width="30%"
  top="3%"
  >
  <div style="width:100%;height:726px; overflow: auto;">
    <img style="width:100%;margin:0 ;" :src="constant.SYSTEM_IMG +'demo.jpg'" alt="">
  </div>
</el-dialog>
  </div>
  </el-card>

</el-container>
</template>

<script>
let that;
import {

 SYSTEM_IMG
} from "../util/constant";
export default {
  data() {
    return {
constant:{
 SYSTEM_IMG
      },
      examplesVisible:false,
      tableData: [],
      pageForm: {
        current: 1,
        size: 10,
        total: 0,
      },
      diaTitle: "",
      weeklyVisible: false,
      weeklyForm: {
        templateWeeklyName:"",
        remarks:"",
        applList:[],
      },
      userList: [],
      checkUsers: [],
      planList: [],
      applicationList:{},
    };
  },
  methods: {
    
    //分页方法
     handleSizeChange(val) {
        that.pageForm.size=val;
        that.getTemplateWeeklyPage();
      },
      handleCurrentChange(val) {
        that.pageForm.current=val;
        that.getTemplateWeeklyPage();
      },
    // 获取模板分页数据
    getTemplateWeeklyPage() {
      that.$api.weekly.getTemplateWeeklyPage(that.pageForm).then((res) => {
        if (res.code == 200) {
          that.tableData = res.data.records;
          [that.pageForm]=[res.data]
        }
      });
    },
    addApplList(list){
      
      if (list.length<5) {
        list.push({
        planId:"",
        dataApplicationId:"",
      })
      }
      
    },
    delApplList(index){
      that.weeklyForm.applList.splice(index,1);
    },
    addWeekly(){
      that.weeklyForm={
        templateWeeklyName:"",
        remarks:"",
        applList:[],
      };
      that.getWeeklyUserList(0);
      that.weeklyVisible = true;
    },
    addTemplateWeekly(){
      that.weeklyForm.userListId=that.checkUsers;
      let arr=[];
      
      that.weeklyForm.applList.forEach(item=>{
        arr.push(item.dataApplicationId);
      });
      that.weeklyForm.applIdList=arr;
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      let url=that.weeklyForm.templateWeeklyId?that.$api.weekly.putTemplateWeekly(that.weeklyForm):that.$api.weekly.postTemplateWeekly(that.weeklyForm);
      url.then(res=>{
        if (res.code==200) {
          that.getTemplateWeeklyPage();
          that.weeklyVisible = false;
         loading.close();
        }
      })
    },
    editWeekly(Id) {
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      that.getWeeklyUserList(Id);
      that.getTemplateWeekly(Id);
      that.getPlanList();
      setTimeout(() => {
        loading.close();
        that.weeklyVisible = true;
      }, 500);
      
    },
    //获取模板信息
    getTemplateWeekly(Id) {
      that.$api.weekly.getTemplateWeekly(Id).then((res) => {
        if (res.code == 200) {
            res.data.applList.forEach((ele) => {
              that.getplanIdProtocolList(ele);
            });
            that.weeklyForm = res.data;
        }
      });
    },
    // 获取用户列表
    getWeeklyUserList(Id) {
      that.$api.weekly.getWeeklyUserList(Id).then((res) => {
        if (res.code == 200) {
          res.data.stopUsers.forEach((item) => {
            res.data.users.forEach((element) => {
              element["disabled"] = false;
              if (element.userId == item) {
                element["disabled"] = true;
              }
            });
          });
          that.checkUsers = res.data.checkUsers;
          that.userList = res.data.users;
        }
      });
    },
    // 获取方案列表
    getPlanList() {
      that.$api.plan.getPlanList().then((response) => {
        if (200 == response.code) {
          that.planList = response.data;
          
        }
      });
    },
    // 获取数据应用列表
    getplanIdProtocolList(item) {
      that.$api.weekly.getplanIdProtocolList(item.planId).then((response) => {
        if (200 == response.code) {
          let obj={...that.applicationList};
          obj[`${item.planId}`] = response.data;
          that.applicationList=obj;
        }
      });
    },
    // 删除周报模板
    delTemplateWeekly(Id) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确 定",
          cancelButtonText: "取 消",
          type: "warning",
          cancelButtonClass: "cancelBtn",
        })
        .then(() => {
          that.$api.weekly.delTemplateWeekly(Id).then((res) => {
            if (res.code == 200) {
              that.getTemplateWeeklyPage();
            }
          });
        });
    },
  },
  created() {
    that = this;
  },
  mounted() {

    that.getTemplateWeeklyPage();
    that.getPlanList();
  },
  beforeDestroy() {},
};
</script>

<style scoped>
::v-deep .form .el-input{
  width: 217px;
}
.weeklyTitle{
  width: 1536px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 40px;
 }
</style>